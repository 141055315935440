<template>
    <el-card class="v_role-list">
      <div class="g-flex" slot="header">
        <span>角色列表</span>
        <div class="g-flex-justify-end g-flex-1">
          <el-button size="mini" type="success" @click="setForm">新增角色</el-button>
        </div>
      </div>
      <el-form :inline="true" size="mini" :model="query">
        <el-form-item>
          <el-select slot="label" v-model="query.search_key" placeholder="查询">
            <el-option label="角色名称" value="name"></el-option>
          </el-select>
          <el-row>
            <el-col :span="18">
              <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
            </el-col>
            <el-col :span="5" :offset="1">
              <el-button type="primary" @click="getList()">查询</el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <el-table stripe size="mini" v-loading="table.loading" :data="table.rows" border>
        <el-table-column prop="id" label="id" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="name" label="角色名称" width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="remark" label="备注" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="创建时间" width="140" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{formatDate(scope.row.create_time, 'yyyy-MM-dd hh:mm')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" width="140" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{formatDate(scope.row.modify_time, 'yyyy-MM-dd hh:mm')}}</span>
          </template>
        </el-table-column>
        <!--  v-if="auth.saveRoleMenu||auth.saveRole||auth.delRole"  -->
        <el-table-column label="操作" width="260">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="setRoleMenu(scope.row)">权限配置</el-button>
            <el-button size="mini" icon="el-icon-edit" type="primary" @click="setForm(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="del(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>

      <!--角色新增和编辑-->
      <el-dialog :title="formData.id?'角色编辑':'新增角色'" :close-on-click-modal="false" :visible.sync="form">
        <el-form :model="formData" label-width="100px">
          <el-form-item v-if="formData.id" label="id">{{formData.id}}</el-form-item>
          <el-form-item label="角色名称">
            <el-input v-model="formData.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="formData.remark" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="form = false">取 消</el-button>
          <el-button type="primary" @click="save()">确 定</el-button>
        </div>
      </el-dialog>

      <!--权限配置-->
      <el-dialog title="权限配置" :close-on-click-modal="false" :visible.sync="roleMenu">
        <el-tree :data="MenuList" ref="tree" node-key="id" :default-checked-keys="MenuChecked" :props="MenuProps" show-checkbox check-strictly default-expand-all highlight-current>
          <template slot-scope="{node}">
            <span :class="nodeColor(node)">{{node.label}}</span>
          </template>
        </el-tree>
        <div slot="footer" class="dialog-footer">
          <el-button @click="roleMenu = false">取 消</el-button>
          <el-button type="primary" @click="saveRoleMenu()">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
</template>

<script>
  import { apiGetRoleList, apiSaveRole, apiGetRoleMenu, apiSaveRoleMenu, apiDelRole } from '@/utils/api.js'
  import { formatDate } from '@/utils/index.js'
  export default {
  name: 'RoleList',
  created(){
    this.getList()
  },
  data() {
    return {
      auth:{
        saveRole: this.$global.ApiList.includes(25),
        delRole: this.$global.ApiList.includes(26),
        saveRoleMenu: this.$global.ApiList.includes(28)
      },

      query: { search_key: 'name',search_val: '', page:1, limit:15},

      table:{loading:false, total:0, rows:[]},

      form: false,

      formData: {},

      roleMenu:false,
      role_id:0,
      MenuList:[],
      //菜单默认选中节点
      MenuChecked:[],
      
      MenuProps: {
        children: 'children',
        label: 'title'
      }
    }
  },
  methods: {
    formatDate,
    //获取列表
    async getList(pageInit = true) {
      if (pageInit) this.query.page = 1
      this.table.loading = true
      const { success, data } = await apiGetRoleList(this.query)
      if(!success) return this.table.loading = false
      this.table.loading = false
      this.table.rows = data.rows
      this.table.total = data.total
    },

    //每页数量改变
    sizeChange(limit) {
      this.query.limit = limit
      this.getList()
    },

    //设置form
    setForm(formData = {}) {
      this.formData = Object.assign({}, formData)
      this.form = true
    },

    async save() {
      if (!this.formData.name) return this.$message.error('角色名称不能为空')
      const loading = this.$loading()
      const { success, data } = await apiSaveRole(this.formData)
      if(!success) return loading.close()
      loading.close()
      this.form = false
      this.$message.success(data.msg)
      this.getList()
    },
    //删除角色
    del(row) {
      this.$confirm('将永久删除'+row.name+'?', '删除提醒').then(async () => {
        const loading = this.$loading()
        const { success, data } = await apiDelRole({id:row.id})
        if(!success) return loading.close()
        loading.close()
        this.$message.success(data.msg)
        this.getList()
      }).catch(() => {})
    },

    // 配置权限
    async setRoleMenu(row) {
      console.log(row)
      const loading = this.$loading()
      this.role_id = row.id
      const {success, data } = await apiGetRoleMenu({id: row.id})
      loading.close()
      if(!success) return 
      this.MenuList = this.tree(data.list,0)
      this.MenuChecked = data.MenuChecked.map(item => item.id)
      this.roleMenu = true
    },

    // 保存权限
    async saveRoleMenu() {
      const menuArr = this.$refs.tree.getCheckedKeys()
      const loading = this.$loading()
      const { success, data } = await apiSaveRoleMenu({role_id: this.role_id, menuArr: menuArr})
      if(!success) {
        loading.close()
        this.roleMenu = false
        return 
      }
      loading.close()
      this.$message.success(data.msg)
      this.roleMenu = false
    },

    tree(MenuArr, parent_id) {
      //菜单
      let MenuList = []
      MenuArr.forEach(item => {
        if (item.parent_id == parent_id) {
          //MenuArr.splice(index, 1)
          let children
          if (MenuArr.length > 0) {
            children = this.tree(MenuArr, item.id)
            if (children.length > 0)
              item.children = children
          }
          MenuList.push(item)
        }
      })
      return MenuList
    },

    nodeColor(node) {
      if (node.data.type == 1) {
        if (node.data.is_show == 0) {
          return 'tree-hide'
        }
      } else {
        return 'tree-api'
      }
    }
  }
}
</script>

<style lang="scss">
  .v_role-list{
    .tree-hide{
      color: #909399;
    }
    .tree-api{
      color: #F56C6C;
    }
  }
</style>